import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Grid,
  Paper,
  Typography,
  CircularProgress,
  styled,
  Chip,
} from "@mui/material";
import axios from "axios";
import { base_url } from "../../apiUtils";
import { useAppStore } from "../../appStore";

const Root = styled("div")(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(2),
}));

const StyledTableContainer = styled(Paper)(({ theme }) => ({
  maxHeight: 400,
  overflowY: "auto",
}));

const TableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#dde7f1",
  color: "#477BA1",
  fontWeight: "bold",
  border: "1px solid #bdbdbd",
  letterSpacing: 0.5,
  position: "sticky",
  top: 0,
  zIndex: 1,
}));

const TableBodyCell = styled(TableCell)(({ theme }) => ({
  fontSize: 14,
  border: "1px solid #bdbdbd",
  padding: theme.spacing(1),
}));

const DataTable = () => {
  const [data, setData] = useState([]);
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortColumn, setSortColumn] = useState("Date");
  const { selectedDateHistory, setSelectedDateHistory } = useAppStore();
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${base_url}game/account-details?date=${selectedDateHistory}`,
        {
          headers: { accesstoken: sessionStorage.getItem("accesstoken") },
        }
      );
      const finalReport = response.data.accountDetails;

      console.log("finalReport", finalReport);

      setData(finalReport);
    } catch (err) {
      console.log("Error fetching data:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedDateHistory) {
      getData();
    }
  }, [selectedDateHistory]);

  useEffect(() => {
    setSelectedDateHistory("");
    setData([]);
  }, []);

  const getBaseGame = (game) => {
    const match = game.match(/^[^\s]*/);
    return match ? match[0] : game;
  };

  const sortData = (data, column, direction) => {
    return [...data].sort((a, b) => {
      if (column === "Date") {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return direction === "asc" ? dateA - dateB : dateB - dateA;
      }
      if (column === "User Name") {
        return direction === "asc"
          ? a.userId.name.localeCompare(b.userId.name)
          : b.userId.name.localeCompare(a.userId.name);
      }
      // Add more columns for sorting as needed
      return 0;
    });
  };

  const handleSortRequest = (column) => {
    const isAsc = sortColumn === column && sortDirection === "asc";
    setSortDirection(isAsc ? "desc" : "asc");
    setSortColumn(column);
  };

  // Sort the data based on the current sort column and direction
  const sortedData = sortData(data, sortColumn, sortDirection);

  return (
    <Root>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StyledTableContainer>
            <Table stickyHeader aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableHeadCell align="center">Sl No</TableHeadCell>
                  <TableHeadCell align="center">
                    <TableSortLabel
                      active={sortColumn === "Date"}
                      direction={sortDirection}
                      onClick={() => handleSortRequest("Date")}
                      sx={{ color: "#477BA1 !important" }}
                    >
                      Date
                    </TableSortLabel>
                  </TableHeadCell>
                  <TableHeadCell align="center">
                    <TableSortLabel
                      active={sortColumn === "User Name"}
                      direction={sortDirection}
                      onClick={() => handleSortRequest("User Name")}
                      sx={{ color: "#477BA1 !important" }}
                    >
                      User Name
                    </TableSortLabel>
                  </TableHeadCell>
                  <TableHeadCell align="center">Game</TableHeadCell>
                  <TableHeadCell align="center">Result</TableHeadCell>
                  <TableHeadCell align="center">Type</TableHeadCell>
                  <TableHeadCell align="center">Vyapar</TableHeadCell>
                  <TableHeadCell align="center">Winning Number</TableHeadCell>
                  <TableHeadCell align="center">Payment</TableHeadCell>
                  <TableHeadCell align="center">Balance</TableHeadCell>
                  <TableHeadCell align="center">Net Balance</TableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableBodyCell colSpan={11} align="center">
                      <CircularProgress />
                    </TableBodyCell>
                  </TableRow>
                ) : sortedData.length === 0 ? (
                  <TableRow>
                    <TableBodyCell
                      colSpan={11}
                      align="center"
                      sx={{ color: "red", fontWeight: "bold" }}
                    >
                      Report Not Found .. 😔
                    </TableBodyCell>
                  </TableRow>
                ) : (
                  sortedData.map((row, index) => (
                    <TableRow key={index}>
                      <TableBodyCell align="center">{index + 1}</TableBodyCell>
                      <TableBodyCell align="center">
                        {new Date(row.createdAt).toLocaleDateString("en-GB")}
                      </TableBodyCell>
                      <TableBodyCell align="center">
                        {row?.userId?.name}
                      </TableBodyCell>
                      <TableBodyCell align="center">
                        {getBaseGame(row?.gameId?.gameName)}
                      </TableBodyCell>
                      <TableBodyCell align="center">
                        {row?.result}
                      </TableBodyCell>
                      <TableBodyCell align="center">
                        {row?.gameId?.gameName}
                      </TableBodyCell>
                      <TableBodyCell align="center">
                        {row?.vyapar > 0
                          ? `${
                              Number(row.vyapar) % 1 !== 0
                                ? Number(row.vyapar).toFixed(2)
                                : Number(row.vyapar)
                            }`
                          : "-"}
                      </TableBodyCell>
                      <TableBodyCell align="left" sx={{ color: "green" }}>
                        {row?.winningNumbers.map((i, index) => {
                          const [beforeSlash, afterSlash] = i.split("/");
                          return (
                            <Chip
                              size="small"
                              key={index}
                              label={
                                <span>
                                  <span
                                    style={{
                                      color: "green",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {beforeSlash}
                                  </span>
                                  /
                                  <span
                                    style={{
                                      color: "gray",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {afterSlash}
                                  </span>
                                </span>
                              }
                              sx={{ ml: 0.5, background: "#e8f5e9" }}
                            />
                          );
                        })}
                      </TableBodyCell>
                      <TableBodyCell
                        align="left"
                        sx={{ color: row?.payment > 0 ? "green" : "gray" }}
                      >
                        {row?.payment > 0
                          ? `+ ${
                              Number(row.payment) % 1 !== 0
                                ? Number(row.payment).toFixed(2)
                                : Number(row.payment)
                            }`
                          : "-"}
                      </TableBodyCell>
                      <TableBodyCell align="center"></TableBodyCell>
                      <TableBodyCell align="center"></TableBodyCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Grid>
      </Grid>
    </Root>
  );
};

export default DataTable;
